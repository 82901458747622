import {useEffect, useRef, useState} from 'react';
import {Link} from '~/components/Link';

const MenuItems = ({mainClass, className, onClose = ''}) => {
  const [moreState, updateMoreState] = useState(false);

  const handleClick = () => {
    updateMoreState((state) => !state);
  };

  const handleClickOutside = () => {
    updateMoreState(false);
  };

  const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [ref]);

    return ref;
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <div className={mainClass}>
      <a href="https://calvaryconnect.com" className={className}>
        Home
      </a>
      <a href="https://calvaryconnect.com/groups" className={className}>
        Groups
      </a>
      <a href="https://calvaryconnect.com/give" className={className}>
        Give
      </a>
      <a
        href="http://www.youtube.com/c/CalvaryConnect"
        target="_blank"
        className={className}
        rel="noreferrer"
      >
        Watch Online
      </a>

      <div className={mainClass + ' md:hidden'}>
        <a href="https://calvaryconnect.com/growth_track" className={className}>
          Growth Track
        </a>
        <Link to="/" className={className + ' nav-active'} onClick={onClose}>
          Shop
        </Link>
        <a href="https://calvaryconnect.com/about" className={className}>
          About Us
        </a>
      </div>

      <div className="hidden md:inline-block w-fit relative" ref={ref}>
        <button
          onClick={handleClick}
          className={className + ' nav-active cursor-pointer'}
        >
          More
        </button>

        <div
          className={
            'text-sm flex-col gap-4 absolute -left-20 top-10 bg-[var(--gray)] rounded rounded-lg z-2 p-5 w-fit w-auto ' +
            (moreState ? 'flex' : 'md:hidden')
          }
        >
          <a
            href="https://calvaryconnect.com/growth_track"
            className={className}
          >
            Growth Track
          </a>
          <Link to="/" className={className + ' nav-active'} rel="noreferrer">
            Shop
          </Link>
          <a href="https://calvaryconnect.com/about" className={className}>
            About Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default MenuItems;
