import logo from '../../../../public/logo.png';
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';

const MobileFooter = () => {
  return (
    <div className="flex flex-col gap-7 border-t border-black/50 py-10 md:hidden">
      <div className="flex justify-between items-center px-3 mx-auto max-w-[600px] sm:gap-14">
        <img className="max-w-[130px]" src={logo} alt="Calvary Church logo" />
        <div className="flex gap-3 w-auto w-fit">
          <a
            className="w-auto w-fit"
            href="https://www.instagram.com/calvarymiami"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="text-lg text-black/70 hover:text-black" />
          </a>
          <a
            className="w-auto w-fit"
            href="https://www.facebook.com/calvarykendall"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook className="text-lg text-black/70 hover:text-black" />
          </a>
          <a
            className="w-auto w-fit"
            href="https://twitter.com/calvarymia"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter className="text-lg text-black/70 hover:text-black" />
          </a>
          <a
            className="w-auto w-fit"
            href="http://www.youtube.com/c/CalvaryConnect"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube className="text-lg text-black/70 hover:text-black" />
          </a>
        </div>
      </div>
      <p className="w-auto w-fit text-[10px] sm:text-xs uppercase text-gray-700 text-center px-5 mx-auto">
        Copyright © {new Date().getFullYear()} Calvary Church All rights
        reserved.
      </p>
    </div>
  );
};

export default MobileFooter;
